import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "@layout/layout"
import { pl, en } from "@content/pages/faq.yaml"
import HeaderSubpage from "@layout/header-subpage"
import SEO from "@layout/seo"

export default ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <Layout isEn={isEn}>
      <HeaderSubpage isEn={isEn}/>
      <SEO
        isEn={isEn}
        title={translation.metaTitle}
        description={translation.metaDescription}
      />
      <section className="section wrapper">
        <section className="padding">
          <div className="row">
            <div className="large-12 medium-12 small-12 columns text-center">
              <h1 className="faq-header margin-0">FAQ</h1>
            </div>
          </div>
        </section>

        <div className="faq-page">
          <ul className="faq-menu">
            <li>
              <a href="#section1" className="active">
                01
              </a>
            </li>
            <li>
              <a href="#section2" className="active">
                02
              </a>
            </li>
            <li>
              <a href="#section3" className="active">
                03
              </a>
            </li>
            <li>
              <a href="#section4" className="active">
                04
              </a>
            </li>
          </ul>
          <div className="section whitebg" id="section1">
            <div className="row">
              <div className="large-11 large-push-1 columns">
                <h5 className="text-center medium-text-left  margin-bottom10">
                  01
                </h5>
                <h2 className="text-center medium-text-left ">
                  {translation.first.title}
                </h2>
                <div className="row">
                  <div className="large-6 medium-12 small-12 columns">
                    <p></p>
                    <p>{translation.first.description}</p>
                    <p></p>
                  </div>
                  <div className="large-6 medium-12 small-12 columns">
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            <a className="scroll_sections noanimation" href="#section2">
              <div className="arrows red">
                <i className="fa fa-angle-double-down"></i>
              </div>
            </a>
          </div>

          <div className="section lightbg" id="section2">
            <div className="row">
              <div className="large-11 large-push-1 columns">
                <h5 className="text-center medium-text-left  margin-bottom10">
                  02
                </h5>
                <h2 className="text-center medium-text-left ">
                  {translation.second.title}
                </h2>
                <div className="row">
                  <div className="large-6 medium-12 small-12 columns">
                    <p></p>
                    <p>{translation.second.description}</p>
                    <p></p>
                  </div>
                  <div className="large-6 medium-12 small-12 columns">
                    <p></p>
                    <p>{translation.second.description2}</p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            <a className="scroll_sections noanimation" href="#section3">
              <div className="arrows red">
                <i className="fa fa-angle-double-down"></i>
              </div>
            </a>
          </div>

          <div className="section redbg" id="section3">
            <div className="row">
              <div className="large-11 large-push-1 columns">
                <h5 className="text-center medium-text-left  margin-bottom10">
                  03
                </h5>
                <h2 className="text-center medium-text-left ">
                  {translation.third.title}
                </h2>
                <div className="row">
                  <div className="large-6 medium-12 small-12 columns">
                    <p></p>
                    <p>F{translation.fourth.description}</p>
                    <p></p>
                  </div>
                  <div className="large-6 medium-12 small-12 columns">
                    <p></p>
                    <p>{translation.fourth.description2}</p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            <a className="scroll_sections noanimation" href="#section4">
              <div className="arrows white">
                <i className="fa fa-angle-double-down"></i>
              </div>
            </a>
          </div>

          <div className="section whitebg" id="section4">
            <div className="row">
              <div className="large-11 large-push-1 columns">
                <h5 className="text-center medium-text-left  margin-bottom10">
                  04
                </h5>
                <h2 className="text-center medium-text-left ">
                  {translation.fourth.title}
                </h2>
                <div className="row">
                  <div className="large-6 medium-12 small-12 columns">
                    <p></p>
                    <p>{translation.fourth.description}</p>
                    <p></p>
                  </div>
                  <div className="large-6 medium-12 small-12 columns">
                    <p></p>
                    <p>{translation.fourth.description2}</p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            <a className="scroll_sections noanimation" href="#section1">
              <div className="arrows red">
                <i className="fa fa-angle-double-up"></i>
              </div>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}
